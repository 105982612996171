<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('document_settings')"
				        :isFilter="false"/>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('document_settings')"
				              :isFilter="false"/>
			</template>
			<div class="row">
				<div class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4" v-if="form">
					<ValidationObserver ref="form">
						<div class="row">
							<div class="col-6 col-md-6 col-xl-4">
								<ValidationProvider name="registrar" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('signer')">
										<parameter-selectbox
                                            code="document_signers"
                                            value-type="name"
											:validate-error="errors[0]"
											v-model="form.registrar">
										</parameter-selectbox>
									</b-form-group>
								</ValidationProvider>
							</div>

                            <div class="col-6 col-md-6 col-xl-4">
                                <ValidationProvider name="title" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('signer_title')">
                                        <parameter-selectbox
                                            code="document_signer_titles"
                                            value-type="name"
                                            text-type="name"
                                            :validate-error="errors[0]"
                                            v-model="form.title">
                                        </parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

							<div class="col-12 col-md-12 col-xl-12 col-xxl-12">
								<div class="row">
									<div class="col-sm-6 col-xs-12 mb-5">
										<b-button @click="save"
										          type="button"
										          variant="primary"
										          class="btn-lg d-flex justify-content-between align-items-center">
											{{ $t('save').toUpper() }}
										</b-button>
									</div>
								</div>
							</div>

						</div>
					</ValidationObserver>
				</div>
			</div>
		</app-layout>
	</div>
</template>
<script>
	// Template
	import AppLayout from "@/layouts/AppLayout";
	import Header from "@/layouts/AppLayout/Header";
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

	// Components
	import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
	import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
	import {ValidationObserver, ValidationProvider} from "vee-validate";

	// Services
	import ConstantService from "@/services/ConstantService";
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import qs from "qs";

	export default {
		components: {
            ParameterSelectbox,
			AppLayout,
			Header,
			HeaderMobile,
			AcademicYearsSelectbox,
			SemestersSelectbox,
			ValidationProvider,
			ValidationObserver,
		},
		data() {
			return {
				form: {
				    registrar: null,
                    title: null,
                },
			}
		},
		metaInfo() {
			return {
				title: this.$t("document_settings")
			}
		},
		methods: {
			async save() {
                if(this.checkPermission('constant_update')){
                    const isValid = await this.$refs.form.validate();
                    if (isValid) {
                        ConstantService.update('documents.registrar', {value: this.form.registrar, type: 'string'}).catch(e=>{
                            this.showErrors(e);
                        }).then(response=>{
                            this.$toast.success(this.$t('updated'));
                        });
                        ConstantService.update('documents.registrar_title', {value: this.form.title, type: 'string'}).catch(e=>{
                            this.showErrors(e);
                        });
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
			},
			getSettings() {
                const config = {
                    params: {
                        filter: {
                            key: 'documents.registrar,documents.registrar_title',
                        },
                        limit: -1,
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                };
				ConstantService.getAll(config).then(response=>{
				    let data = this.groupBy(response.data.data, 'key');
				    if(data['documents.registrar']){
				        this.form.registrar = data['documents.registrar'][0].value;
                    }
				    if(data['documents.registrar_title']){
                        this.form.title = data['documents.registrar_title'][0].value;
                    }
                });
			}
		},
		created() {
			this.getSettings()
		}
	}
</script>
